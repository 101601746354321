.our-work{
    padding:100px 0 0;
    .container{
        max-width:90%;
        .h-padding{
            padding:80px 0;
            overflow: hidden;
        }
        .description{
            width:65%;
            h2{font-weight: bold; font-size: 30px;}
            p{ font-size: 15px;}
        }
        .navy-section{
            .greenbox{
                background: #ADCC49;
            }
        }
        .elilillyco-section{
            position: relative;
            #elilillylogo{
                width:10vw;
            }
            .description{
                position: absolute;
                bottom:0;
            }
        }
        .airlines-section{
            padding:100px 0 0;
            .university-section{
                .description{
                    width:75%!important;
                    img{
                        width:35%;
                        padding-bottom: 30px;
                    }
                }
            }
        }
        .generalmill-section{
            position: relative;
            .description{
                width:100%;
            }
            .greenbox{
                background: #ADCC49;
                position: absolute;
                width:200px;
                height:200px;
                left:50%;
                top:20%;
                z-index:-1;
            }
        }
    }
}

@media (min-width:'769px') and (max-width:'1300px') {
    .our-work{
        .container{            
            .generalmill-section{
                .greenbox{
                    width:150px;
                    height:150px;
                }
            }
        }
    }       
}

@media (min-width:'320px') and (max-width:'768px') {
    .our-work{
        padding:50px 0 0;
        .container{
            max-width:90%;
            .h-padding{
                padding:40px 0;
                overflow: hidden;
            }
            .description{
                width:85%;
                padding:20px 0;
                h2{font-weight: bold; font-size: 26px;}
                p{ font-size: 15px;}
            }
            .navy-section{
                .greenbox{
                    background: #ADCC49;
                }
            }
            .elilillyco-section{
                position: relative;
                #elilillylogo{
                    width:15vw;
                }
                .description{
                    width:100%;
                    position: relative;
                    bottom:0;
                }
            }
            .airlines-section{
                padding:50px 0 0;
                #airline1{
                    width:100%;
                    height:450px;
                }
                .university-section{
                    .description{
                        width:90%!important;
                        img{
                            width:35%;
                        }
                    }
                }
                
                #universityimg{
                    width: 100%;
                    height:500px;
                }
            }
            .generalmill-section{
                .description{
                    width:95%;
                }
                .greenbox{
                    width:100px;
                    height:100px;
                    left:40%;
                    top:25%;
                }
            }
        }
    }     
}
@media (min-width:'320px') and (max-width:'481px') {
    .our-work{
        padding:50px 0 0;
        .container{
            max-width:90%;
            .h-padding{
                padding:40px 0;
                overflow: hidden;
            }
            .description{
                width:100%;
                padding:20px 0;
                h2{font-weight: bold; font-size: 26px;}
                p{ font-size: 15px;}
            }
            .navy-section{
                .greenbox{
                    background: #ADCC49;
                }
            }
            .elilillyco-section{
                position: relative;
                #elilillylogo{
                    width:15vw;
                }
                .description{
                    width:100%;
                    position: relative;
                    bottom:0;
                }
            }
            .airlines-section{
                padding:50px 0 0;
                #airline1{
                    width:100vw; 
                }
                #airline2{
                    display: none;
                }
                .university-section{
                    .description{
                        width:90%!important;
                        img{
                            width:35%;
                        }
                    }
                }
                #universityimg{
                    height:350px;
                }
            }
            .generalmill-section{
                .description{
                    width:95%;
                }
                .greenbox{
                    width:100px;
                    height:100px;
                    left:40%;
                    top:25%;
                }
            }
        }
    }     
}