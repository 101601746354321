.projects{
    padding:100px 0;
    .container{
        max-width:90%;            
        .title{
            hr{
                width:200px!important;
                background: #000;
                margin:10px 0;
                height:1px;
            }
            h2{
                padding:0;
            }
        }
    }
    .project-slider-container{        
        margin:20px 0px 20px;
        padding-left:80px!important;
        .slideritem{
            min-height:350px;
            background-size:cover;
            position: relative;
            border-radius: 22px;
            h3{
                padding:8px 25px;
                font-size:20px;
                background: #ADCC49;
                color:#fff;
                border-radius: 50px;
                display: inline;
                position: absolute;
                bottom:10px;
                left:10px;
            }
        }
        .swiper-slide-active{
            width:550px!important;
            max-width:550px!important;
            transition: all linear;
        }    
        .swiper-slide{
            min-width:350px!important;
        }
    }
        
    .custom-nav{    
        position: absolute;
        bottom: 15%;
        left: 30%;
        transform: translate(-15%,-25%);
        z-index: 9;
        &::after{
            content:'';
            position: absolute;
            top:-25px;
            left:-15px;
            background-color: #000;
            width:80px;
            height:80px;
            border-radius: 100px;
            z-index: -1;
        }
        &:hover{
            &::after{
                background-color: #ADCC49;
            }
            svg:hover{
                scale:1.2
            }
        }
    }
}

@media (min-width:768px) and (max-width:1024px){
    .projects{ 
        .project-slider-container{
            padding-left:40px!important;
            .slideritem{
                min-height:300px;
            }
            .swiper-slide-active{
                width:500px!important;
                max-width:500px!important;
            }    
            .swiper-slide{
                min-width:300px!important;
            }
        }
        .custom-nav{    
            bottom: 15%;
            left:45%;
            transform: translate(-45%,-25%);
        }
    }
}


@media (min-width:576px) and (max-width:768px){
    .projects{ 
        .project-slider-container{
            padding:0 40px!important;
            .slideritem{
                min-height:400px;
            }
            .swiper-slide-active{
                width:100%!important;
                max-width:100%!important;
            } 
        }
        .custom-nav{    
            bottom: 15%;
            left:90%;
            transform: translate(-45%,-25%);
        }
    }
}

.hide-mob{
    display: inline-block!important;
}
.hide-desktop{
    display: none!important;
}

@media (min-width:300px) and (max-width:575px){
    .projects{ 
        padding:50px 0;
        .project-slider-container{
            padding:0 20px!important;
            .slideritem{
                min-height:200px;
            }
            .swiper-slide{                
                width:100%!important;
                min-width:100%!important;
            }
            .swiper-slide-active{
                width:100%!important;
                max-width:100%!important;
            } 
        }
        .custom-nav{    
            display: flex;
            gap:10px;
            bottom: 10%;
            left:80%!important;
            transform: translate(-45%,-10%);
            button{
                background: #000;
                padding:8px 14px;
                border-radius: 100%;
            }
            &::after{
                display: none;
            }
        }
    }
    .hide-desktop{
        margin-top:20px!important;
        display: inline-block!important;
    }
    .hide-mob{
        display: none!important;
    }
}

