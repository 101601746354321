.hero-slider-wrapper{
    .hero-slider{
        padding:50px 0 100px;
        height:100dvh;
        position: relative;
        overflow: hidden;
        .mobileimg{
            display: none;
        }
        .content{
            width:65%;
            img{
                position: absolute;
                left:0;
                top:65%;
                transform: translate(0,-65%);
            }
            h2{
                font-family: 'Abhaya Libre', serif;
                font-size:55px;
                font-weight:bold;
            }
            p{
                width:60%;
            }
        } 
    }
    .diagonal-bg{
        background: -webkit-linear-gradient(-25deg, rgb(255, 255, 255) 50%, rgba(55, 230, 235, 0) 0%),url('../../Assets/slider2.png');
        background-size: cover; 
        #hide-desktop{
            display: none;
        }   
        .container{
            max-width:90%;
            height:100%;
            .row{
                height: inherit;
                .content{
                    width:70%;
                }
            }
        }
    } 
    .slide3{
        .container{
            max-width:90%;
            height:100%;
            .row{
                height: inherit;
                .content{
                    width:95%;
                    color:#fff!important;
                    p{
                        width:100%;
                    }
                }
                #heroimg3{
                    width:100%;
                }
            }
        }
        &::after{
            content:'';
            z-index: -1;
            background: #ADCC49;
            width:78dvw;
            height:75dvh;
            position: absolute;
            top:0;
            left:0;
        }
    }
    .swiper-pagination {
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, -5%);
        width: 140px;
        color: transparent;
        background: #fff;
        padding: 3px;
        border-radius: 35px;
        &::after{
            content:'-';
            width:25px;
            height:1px;
            position:absolute;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            background: #000;
        }
        span{
            color:#000;
            padding:0 20px; 
        }
    }
}

.custom-nav{   
    position: absolute;
    bottom: 5%;
    left: 20%;
    transform: translate(-80%,-5%);
    z-index: 9;
    button{
        background: transparent;
        border:none;
    }
}

// Small Screens 
@media (min-width:1024px) and (max-width:1280px){
    .hero-slider-wrapper{
        .hero-slider{
            padding:50px 0;
            position: relative;
            overflow: hidden;
            .mobileimg{
                display: none;
            }
            .content{
                width:65%;
                img{
                    position: absolute;
                    left:0;
                    top:65%;
                    transform: translate(0,-65%);
                    width:20vw;
                }
                h2{
                    font-family: 'Abhaya Libre', serif;
                    font-size:42px;
                    font-weight:bold;
                }
                p{
                    width:72%;
                }
            }
        }
    } 
}

//Tabel Landscape
@media (min-width:768px) and (max-width:1023px){
    .hero-slider-wrapper{
        .hero-slider{
            padding:50px 0;
            position: relative;
            overflow: hidden;
            .content{
                img{
                    width:22vw;
                }
                h2{
                    font-size:35px!important;
                }
                p{
                    width:90%!important;
                }
            }
            #heroimg2{
                width:100%;
                object-fit: cover;
                height:70dvh!important;
            }
        }
    }
}

@media (min-width:576px) and (max-width:767px){
    .hero-slider-wrapper{
        .hero-slider{
            padding:50px 15px 100px;
            .mobileimg{
                display: block!important;
                img{
                    width:98%;
                    padding-bottom:15px;
                }
            }
            #heroimg1,#heroimg2{
                display: none;
            }
            .content{
                width:100%;
                img{
                    width:150px;
                    position: absolute;
                    left:-5%;
                    top:65%;
                    transform: translate(0,-65%);
                }
                h2{
                    font-family: 'Abhaya Libre', serif;
                    font-size:30px;
                    font-weight:bold;
                }
            }
        }  
        .custom-nav{   
            left: 20%!important;
        }
    }
}


@media (min-width:300px) and (max-width:576px){
    .hero-slider-wrapper{        
        .hero-slider{
            padding:50px 15px 100px;
            height:100%;
            .mobileimg{
                display: block!important;
                img{
                    width:98%;
                    padding-bottom:15px;
                }
            }
            #heroimg1,#heroimg2{
                display: none;
            }
            .content{
                width:100%;
                img{
                    width:150px;
                    position: absolute;
                    left:-5%;
                    top:65%;
                    transform: translate(0,-65%);
                }
                h2{
                    font-size:23px;
                }
                p{
                    width:100%;
                }
            }
        }  
        .custom-nav{   
            position: absolute;
            bottom: 10%;
            width: 100%;
            text-align: center;
            left: 50%!important;
            transform: translate(-50%,-10%);
            z-index: 9;
            button{
                background: transparent;
                border:none;
            }
        }
    }
    .diagonal-bg{
        background: #ffffff!important;
        img{
            width:100%;
            margin-bottom: 40px;
        }
        #hide-desktop{
            display: block!important;
        } 
        .container{
            max-width:95%!important;
            .row{
                .content{
                    width:100%!important;
                }
            }
        }
    } 

    .slide3{
        .container{
            max-width:95%!important;
            .row{
                .content{
                    width:100%!important;
                    color:#fff!important;
                }
                #heroimg3{
                    width:100%;
                    margin-top:50px;
                }
            }
        }
        &::after{
            width:100dvw!important;
            height:65dvh!important;
        }
    }   
}