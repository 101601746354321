.who-we-are{
    background:#ADCC49;
    padding:100px 0 200px;
    color:#fff;
    position: relative;
    margin-bottom: 130px;
    .container{
        max-width:90%;
    }
    .title{
        hr{
            width:80%!important;
            background: #fff;
            margin:10px 0;
        }
        h2{
            padding:0;
        }
    }
    .btn{
        a{
            color:#fff!important;
        }
        border:1px solid #fff!important;
        &:hover{
            border:1px solid #000!important;

        }
    }
    #floatimg{
        width:55vw;
        position: absolute;
        left:50%;
        transform: translate(-50%);
        bottom:-130px;
        z-index:1;
    }
}

@media (min-width:768px) and (max-width:1024px){
    .who-we-are{
        padding:100px 0 200px;
        .container{
            max-width:90%;
        }
        .title{
            hr{
                width:70%!important;
                margin:15px 0;
            }
            h2{
                padding:0;
                font-size:35px;
            }
        }
        #floatimg{
            width:60vw;
            position: absolute;
            left:50%;
            transform: translate(-50%);
            bottom:-160px;
            z-index:1;
        }
    }
      
}


@media (min-width:576px) and (max-width:780px){
    .who-we-are{
        padding:50px 0 50px;
        .container{
            max-width:90%;
            .btn{
                width:100%!important;
                padding:15px 10px!important;
            }
        }
        .title{
            hr{
                width:70%!important;
                margin:15px 0;
            }
            h2{
                padding:0;
                font-size:35px;
            }
        }
        #floatimg{
            width:60vw;
            position: absolute;
            left:50%;
            transform: translate(-50%);
            bottom:-160px;
            z-index:1;
        }
    }   
}
@media (min-width:300px) and (max-width:575px){
    .who-we-are{
        padding:50px 0 50px!important;
        margin:0;
        .container{
            max-width:90%;
            .btn{
                width:100%!important;
                padding:15px 10px!important;
            }
        }
        .title{
            hr{
                width:70%!important;
                margin:15px 0;
            }
            h2{
                padding:0;
                font-size:26px;
            }
        }
        .btn{
            margin:20px 0;
        }
        #floatimg{
            display: none;
        }
    } 
}