@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: 'Source Sans 3', sans-serif!important;
  overflow-x: hidden;
}

.no-padding{
  padding:0!important;
}

h1,h2,h3,h4,h5,h6{ 
  font-family: 'Abhaya Libre', serif; 
}

.title{
  h5{
      font-family: 'Source Sans 3', sans-serif!important; 
      font-size:14px;
      font-weight:600;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
  }
  hr{
      width:120px;
      height:0.8px;
      background:#174689;
  }
  h2{
      font-family: 'Abhaya Libre', serif;
      font-size:50px;
      font-weight: bold;
      padding:0 10vw; 
  }
}


@media (min-width:481px) and (max-width:768px){
  .title{
    h5{
        font-size:14px;
        font-weight:600;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
    }
    hr{
        width:120px;
        height:0.8px;
        background:#174689;
    }
    h2{
        font-family: 'Abhaya Libre', serif;
        font-size:35px;
        font-weight: bold;
        padding:0 10vw; 
    }
  }
}
@media (min-width:320px) and (max-width:480px){
  body{
    overflow-x: hidden;
  }
  section{
    overflow: hidden;
  }
  .title{
    h5{
        font-size:12px;
        font-weight:600;
        letter-spacing: 0.2rem;
        text-transform: uppercase;
    }
    hr{
        width:100px;
        height:0.8px;
        background:#174689;
    }
    h2{
        font-family: 'Abhaya Libre', serif;
        font-size:26px;
        font-weight: bold;
        padding:0 10vw; 
    }
  }
}