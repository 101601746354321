.banner{
    height:50vh;
    background-size: cover!important;
    .container{
       max-width:90%;
    }
    h2{
        font-size:55px;
        color:#fff;
    }
    p{
        font-size:17px;
        color:#fff;
        width:50%;
    }
}

@media (min-width:320px) and (max-width:576px){
    .banner{
        height:60vh;
        background-position: center center;
        h2{
            font-size:45px;
        }
        p{
            font-size:16px;
            width:100%;
        }
    } 
}