.blog-section{
    padding:100px 0;
    .container{
        max-width:90%;
        .title{
            hr{
                background:#000;
                width:50%;
                margin:0;

            }
            h2{
                padding:20px 0;
            }
        }

        .blog-content{
            padding:15px;
            width:100%;
            background: #fff;
            .d-flex{
                gap:30px;
                padding-bottom:10px;
                span{
                    font-weight: bold;
                    font-size:15px;
                }
            }
            h3{
                font-size:23px;
                font-weight: bold;
            }
            hr{
                height:1.4px;
                background-color: #000;
                width:25%;
                margin:10px 0;
            }
            p{
                line-height: 30px;
            }
        }

        .blog-featured-section{
            .blog-featured{
                background:url('../../Assets/blog-featured.jpg');
                background-size: cover;
                min-height:350px;
                padding:20px;
                position: relative;
                margin-bottom:100px;
                .blog-content{
                    position: absolute;
                    bottom:10px;
                    right:10px;
                    width:80%;
                }
            }
            .btn{
                float:right;
            }
        }

        .blog-wrapper{
            padding:15px 0;
            img{width: 100%;}
            .blog-content{
                padding:0;
            }
        }
    }
    .all-blog{
        display: block;
    }
}

@media (min-width:768px) and (max-width:1200px){
    .all-blog{
        display: grid!important;
        grid-template-columns: auto auto;
        gap:20px;
        .blog-content{
            padding-top:20px!important;
        }
    }
}

@media (min-width:300px) and (max-width:575px){
    .blog-section{
        padding:50px 0;
    }
    .blog-wrapper{
        .blog-content{
            margin-top:10px!important;
        }
    }
    .blog-featured-section{
        .blog-featured{
            background:url('../../Assets/blog-featured.jpg');
            background-size: cover;
            min-height:350px!important;
            padding:20px;
            position: relative;
            margin-bottom:20px!important;
            .blog-content{
                position: absolute;
                bottom:10px;
                right:10px;
                width:90%!important;
                p{
                    line-height: 20px!important;
                }
            }
        }
        .btn{
            float:right;
        }
    }
}