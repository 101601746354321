.workflow{
    padding:100px 0;
    .container{
        max-width: 90%;
        .title{
            h2{
                padding: 0!important;
            }
        }
    }
    .box-content{
        margin-top: 20px;
    }
}