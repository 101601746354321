.blog-container{
    padding:100px 0;
    .container{
        max-width: 90%;
        .blogs-items{
            display: flex;
            padding:20px 0;
            .img-wrapper{
                img{
                    width:350px;
                    height:250px;
                    object-fit: cover;
                    padding:0 10px;
                }                
            }
            .parameters-fullview{
                display: none;
                gap:20px;
                justify-content: center;
                text-align: center;
            }
            .blog-content-wrapper{
                padding:0 10px;
                .blog-content{
                    .parameters-shortview{
                        display:flex;
                        gap:25px;
                        padding-bottom:10px;
                        span{
                            font-weight: 600;
                        }  
                        .category{ color:#174689}                      
                    }
                    .title{
                        h3{
                            font-weight: bold;
                        }
                    }
                    button{
                        background: none;
                        border:none;
                        padding: 0;
                        color:#174689;
                        text-transform: uppercase;
                        border-bottom:2px solid #174689;
                    }
                    p{
                        color:#5d5d5d
                    }
                    .longdescription{
                        display: none;
                    }
                }
            }
        }
        .sidebarbox{            
            border:1px solid #C8C8C8;
            padding:20px 15px;
            margin-bottom: 30px;
            hr{
                width:30px;
                margin-top:5px;
                background: #174689;
                height:1px;
            }
            span{
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        .newsletter{  
            span{
                text-transform: uppercase;
            } 
        }
        .social-icons{
            border:1px solid #C8C8C8;
            padding: 15px;
            text-align: center;
            
            .d-flex{
                gap:25px;
                align-items: center;      
                justify-content: center;  
                span{
                    width:35px;
                    height:35px;
                    border:1px solid #00000030;
                    text-align: center;
                    border-radius: 100%; 
                    padding-top:5px;               
                    &:hover{
                        background: #174689;
                        svg{
                            path{
                                fill:#fff;
                            }
                        }
                    }
                } 
            }          
        }
        .latestposts{
            text-align: center;
            .row{
                text-align: left;
                padding-bottom:10px;
                img{
                    width:100%;
                    height:100%;
                    object-fit: cover;
                }
                p{
                    font-weight: 600;
                }
            }
        }
        .categories{
            text-align: center;
            ul{
                list-style:disc;                
                padding-left:15px;
                text-align: left;
                padding-top:20px;
            }
        }
    }
}


@media (min-width:'991px') and (max-width:'1200px') {
    .blog-container{
        padding:50px 0;
        .container{
            max-width: 95%;
            .blogs-items{
                display: block;
                padding:10px 0;
                .img-wrapper{
                    padding:10px 0;
                    img{
                        width:100%;
                        height:300px!important;
                    }                
                }
            }
            .newsletter{
                margin-top: 20px;           
            }            
        }
    }

}


@media (min-width:'320px') and (max-width:'481px') {
    .blog-container{
        padding:50px 0;
        .container{
            max-width: 95%;
            .blogs-items{
                display: block;
                padding:10px 0;
                .img-wrapper{
                    padding:10px 0;
                    img{
                        width:90vw;
                        height:200px!important;
                    }                
                }
            }
            .newsletter{
                margin-top: 20px;           
            }            
        }
    }

}
