header{
    .container{
        max-width: 90%;
    }
    .top-header{
        padding:30px 0;
        padding-bottom:10px!important;
        .header-info{
            display:flex;
            gap:50px;
            align-items: center;
            .header-info-item{
                a{
                    color:#000;
                    display:flex;
                    gap:10px;
                    align-items: center;
                    font-weight: bold;
                    font-size:16px;
                    svg{
                        animation: none;                        
                    }
                    &:hover{
                        text-decoration: none;
                        svg{
                            animation: shake 200ms ease-in-out;
                        }
                    }
                    @keyframes shake {
                        0%{
                            rotate: 10deg;
                        }   
                        50%{
                            rotate:0deg;
                        }
                        100%{
                            rotate:10deg;
                        }
                    }
                }
            }
        }
        .social-icons{
            display:flex;
            gap:25px;
            align-items: center;      
            justify-content: end;  
            span{
                width:35px;
                height:35px;
                border:1px solid #00000030;
                text-align: center;
                border-radius: 100%; 
                padding-top:2.5px;   
                animation: none;    
                &:hover{
                    background: #174689;
                    animation: socialmediaanimation 200ms ease-in-out;
                    svg{
                        path{
                            fill:#fff;
                        }
                    }
                }
                @keyframes socialmediaanimation {
                    0%{
                        scale:0.8;
                    }
                    100%{
                        scale:1.1;
                    }
                }
            }  
            .svghover{
                &:hover{
                    background: #174689;
                    svg{
                        path{
                            fill:#174689!important;
                            stroke:#fff!important;
                            stroke-width: 1.4px;
                        }

                    }
                }
            }  
        }
    }
    .main-header{        
       padding:10px 0 0;
       #logo{
        width:10rem;
       }    
        .menu{
            background:#174689;
            padding:0px 0px 0px 30px;
            color:#fff;
            display:grid;
            align-items: center;
            grid-template-columns: 75% 25%;
            ul{
                display: flex;
                font-size:15px;
                float: left;
                gap:25px;
                list-style-type: none;
                padding:0;
                margin:0;
                text-transform: uppercase;
                li{
                    a{
                        color:#fff;
                        position: relative;
                        &::after{
                            content:'';
                            position: absolute;
                            left:0%;
                            bottom:0;
                            width:0%;
                            height:1.5px;
                            background: #fff;
                        }
                        &:hover{
                            text-decoration: none;
                            &::after{
                                width:100%;
                                transition: 200ms ease-in;
                            }
                        }
                    }
                }
            }
            button{
                background:#ADCC49;
                font-size:14px;
                padding:25px 20px;
                border:none;
                color:#fff;
                float:right;
                text-transform: uppercase;
                &:hover{
                    background: #000;
                    transition: 0.4s linear;
                }
            }
        }
    }
    
    .main-header-mobile{
        display: none;
    }
    .newsletter-wrapper{
        .overlay{
            content: '';
            position: fixed;
            width:100vw;
            height:100vh;
            top:0;
            left:0;
            background: rgb(0, 0, 0,0.4);
            z-index:8;            
        }
        .newsletter{
            position: fixed;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            z-index:9;
            background-color: #fff;
            border-radius: 10px;
            width:25vw;
            padding:20px 25px;
            form{
                input{
                    border-radius: 20px;
                }                
            }
        }
    }
}

@media (min-width:576px) and (max-width:768px){
    header{
        .container{
            max-width: 95%;
        }
        .top-header{
            padding: 10px 0;
            .header-info{
                display: flex;
                
                .header-info-item{
                    display:flex;
                    font-size:14px;
                    padding:5px 0;
                    svg{
                        scale:0.8;
                    }
                }
            }
            .social-icons{
                display: none;
            }
        }

        .main-header{
            display: none;
        }

        .main-header-mobile{
            display: block;
        }

        .main-header-mobile{
            padding:10px 0px;
        }

        .main-header-mobile svg{
            margin-right:30px;
        }
        .mobilemenu{
            position: fixed;
            top:0;
            left:0;
            z-index: 99999;
            width:100vw;
            height:100vh;
            color:#fff;
            background-color: #174689;
            padding: 20px;
            animation: slideright 0.7s ease;
            transition: 0.7s ease;
            display: none; 
            @keyframes slideright {
                0%{
                    left:-110%;
                }
                100%{
                    left:0%;
                }
            }  
            ul{
                padding: 0;
                list-style: none;
                li{
                    padding-bottom:20px;
                }
            }
            a{
                color:#fff;
                font-size:17px;
                padding-bottom:20px;
            }
            svg{
                position: absolute;
                top:20px;
                right:20px;
                path{
                    fill:#fff;
                    stroke: #fff;
                }
            }
        }
        
        
    } 
}
@media (min-width:320px) and (max-width:575px){
    header{
        background: #174689;
        .top-header{
            display: none;
        }
        .container{
            max-width: 90%;
        }
        .top-header{
            padding: 5px 0;
            .header-info{
                gap:10%;
                padding-bottom:0px;
                .header-info-item{
                    display:flex;
                    font-size:14px;
                    padding:5px 0;
                    svg{
                        scale:0.8;
                    }
                }
            }
            .social-icons{
                display: none;
            }
        }
        .main-header{
            display: none;
        }

        .main-header-mobile{
            display: block;
            padding:5px 0px;
            svg{
                rect{
                    fill:#fff;
                }
            }
        }

        .mobilemenu{
            position: fixed;
            top:0;
            left:0;
            z-index: 99999;
            width:100vw;
            height:100vh;
            color:#fff;
            background-color: #174689;
            padding: 20px;
            animation: slideright 0.7s ease;
            transition: 0.7s ease;
            display: none; 
            @keyframes slideright {
                0%{
                    left:-110%;
                }
                100%{
                    left:0%;
                }
            }  
            ul{
                padding: 0;
                list-style: none;
                li{
                    padding-bottom:20px;
                }
            }
            a{
                color:#fff;
                font-size:17px;
                padding-bottom:20px;
            }
            svg{
                position: absolute;
                top:20px;
                right:20px;
                path{
                    fill:#fff;
                    stroke: #fff;
                }
            }
        }
    }  
    .newsletter{
        width:80vw!important;
    }
}